import { api, createCrudService } from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpContractsPosition',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.calculate = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.post(
      `/api/controltower/takeUpContractsPosition/calculate${filter}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export default service

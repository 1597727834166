<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpContractsPositionService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMeta()
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey:
            'controlTower.pages.takeUpContractsPosition.messages.calculate',
          onClick: this.onCalculateHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-calculator'
        }
      ]
      return meta
    },
    async onCalculateHandler() {
      const confirm = await this.confirmCalculateByFilterCompleted()
      if (confirm) {
        try {
          await this.calculate()
        } catch (err) {
          console.error('Failed to calculate contracts position', err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async calculate() {
      const resp = await service.calculate()
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else if (resp) {
        this.notify.success({
          title: this.$t(
            'controlTower.pages.takeUpContractsPosition.messages.calculateSuccessMsg'
          )
        })
      } else {
        this.notify.error({
          title: this.$t(
            'controlTower.pages.takeUpContractsPosition.messages.calculateWithErrors'
          )
        })
      }
      this.reloadData = true
    },
    async confirmCalculateByFilterCompleted() {
      let confirm = await this.confirmCalculateByFilter()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmCalculateByFilterOnAllItems()
      }
      return confirm
    },
    async confirmCalculateByFilter() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpContractsPosition.messages.confirmCalculateByFilter'
        )
      })
    },
    async confirmCalculateByFilterOnAllItems() {
      return helpers.createConfirmPromise(
        this,
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpContractsPosition.messages.confirmCalculateByFilterOnAllItems'
          )
        },
        () => service.hasFilterApplied == null || service.hasFilterApplied()
      )
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    hasErrors(response) {
      return response.error
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
